import { CButton } from "@coreui/react";
import React, { useEffect, useState } from "react";
import useLogout from "utils/useLogout";
import { useTranslation } from "react-i18next";
import { ThirdlyButton } from "./ThirdlyButton";
import { ActionButton } from "./ActionButton";

interface Props {
  children?: any;
  hasFullHeight?: boolean;
}

const Loader = ({ children, hasFullHeight = true }: Props) => {
  const { t } = useTranslation();
  const { handleLogout } = useLogout();

  const [showActions, setShowActions] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setShowActions(true);
    }, 6000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className={`Loading ${hasFullHeight ? "Loading-height" : ""}`}>
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      {/* {showActions && (
        <div
         className="actions-container"
        >
          <ActionButton title={t("logout")} onClick={() => handleLogout()} />
          <ActionButton
            title={t("reload")}
            onClick={() => window.location.reload()}
          />
        </div>
      )} */}
      {children}
    </div>
  );
};

export default Loader;
