import { logoutLink } from "utils/SSOUtil";
import { useState } from "react";
import { post } from "services/ApiFetching";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getEnv } from "env";

const useLogout = () => {
  const [isKeyPressed, setIsKeyPressed] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const setIsLogged = (state: any) =>
    dispatch({ type: "set", isLogged: state });
  const setOrganizationsListState = (state: any) =>
    dispatch({ type: "set", selectedOrganization: state });

  const handleLogout = async () => {
    if (getEnv().NAMAA_ENV === "DEV") {
      try {
        const response = await post("/auth/logout", {
          email: localStorage.getItem("email")?.toLocaleLowerCase(),
        });
        if (response.data.statusCode === 200) {
          let lang = localStorage.getItem("i18nextLng")!;
          setIsLogged(false);
          setOrganizationsListState({});
          localStorage.clear();
          localStorage.setItem("i18nextLng", lang);
          return navigate("/");
        }
      } catch (error) {
        const { response } = error as any;
        return response;
      }
    } else {
      if (!isKeyPressed) {
        // window.open(logoutLink, "_self", "noreferrer");
        localStorage.clear();
        navigate('/redirect')
        setIsKeyPressed(true);
        setIsKeyPressed(false);
      } else return;
    }
  };
  return { handleLogout };
};
export default useLogout;
